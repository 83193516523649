import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from '../../models/menu-item';

@Pipe({
  name: 'callbackFilter',
  pure: false
})
export class CallbackFilterPipe implements PipeTransform {
  transform(
    items: MenuItem[] | undefined,
    callback: (MenuItem: any) => boolean
  ): MenuItem[] {
    if (!items || !callback) {
      return items ? items : [];
    }
    return items.filter((item) => callback(item));
  }
}
