<ul
  [ngClass]="'submenu-' + submenu"
  [ngStyle]="{
    'border-left': '2px solid ' + (parent && parent.color ? parent.color : '')
  }"
>
  <li *ngFor="let item of items | callbackFilter: showMenu">
    <div
      *ngIf="item.children && showMenu(item)"
      [class.menu--active-parent]="item.active"
      class="parent-item"
    >
      <div class="parent-item--value">{{ item.label | translate }}</div>
      <app-menu-dropdown
        (navigationEvent)="navigation($event)"
        [items]="item.children"
        [parent]="item"
        [submenu]="submenu + 1"
      ></app-menu-dropdown>
    </div>
    <a
      (click)="navigation($event)"
      *ngIf="!item.children && showMenu(item)"
      [routerLink]="[item.url]"
      routerLinkActive="menu--active-item"
      >{{ item.label | translate }}
    </a>
  </li>
</ul>
