<div class="wrapper">
  <div class="horizontal-menu container">
    <div class="copyright">
      © 2022 - {{currentYear}} BMW Group
    </div>
    <div class="legal">
      <a [routerLink]="['/terms-and-conditions']">{{ 'Terms and conditions' | translate }}</a>
    </div>
  </div>
</div>
