<div class="__header-wrapper">
  <div *ngIf="brand === 'GROUP' && !isUsHub">
    <app-group-header
      (closeMenu)="handleCloseMenuClick()"
      (menuToggled)="toggleMenu()"
    ></app-group-header>
  </div>

  <div *ngIf="brand === 'BMW' || (brand === 'GROUP' && isUsHub)">
    <app-bmw-main-navigation
      (menuSelected)="handleMenuSelected($event)"
    ></app-bmw-main-navigation>
  </div>

  <div *ngIf="brand === 'MINI'">
    <app-mini-main-navigation
      (menuSelected)="handleMenuSelected($event)"
    ></app-mini-main-navigation>
  </div>

  <div
    [class.opened]="opened"
    class="grp-mainnavigation-flyout grp-component__fullwidth"
  >
    <div class="grp-component__fullwidth__inner">
      <div *ngIf="selectedMenu" class="row menu">
        <div class="col">
          <i
            (click)="handleCloseMenuClick()"
            class="iwp-icon-gen_close close"
            aria-hidden="true"
          ></i>
        </div>
        <div class="col-6">
          <app-menu-dropdown
            (navigationEvent)="handleDropdownMenu($event)"
            [items]="selectedMenu.children"
            [parent]="selectedMenu"
            [submenu]="0"
          >
          </app-menu-dropdown>
        </div>
      </div>
    </div>
  </div>

  <app-menu
    (closeMenuEvent)="handleCloseSideMenu()"
    (navigationEvent)="handleNavigationEventMenu()"
    [class.sidebar-opened]="menuState"
    [items]="menuService.getMenuItems()"
    [submenu]="0"
    class="sidebar"
  >
  </app-menu>
  <div [class.opened]="opened" id="grp-exit-flyout"></div>
</div>
