import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegrationService } from '../../../services/integration/integration.service';
import { ShowDetailsWrapper } from '../../../dtos/ShowDetailsWrapper';
import { ViewSource } from '../../../dtos/ViewSource';
import { AssignmentDto } from '../../../dtos/AssignmentDto';
import { AssignmentActionType } from '../../../dtos/AssignmentActionType';
import {DataService} from "../../../services/data.service";
import { AssignmentStatus } from '../../../dtos/AssignmentStatus';

@Component({
  selector: 'app-us-assignment-card[assignment]',
  templateUrl: './us-assignment-card.component.html',
  styleUrls: ['./us-assignment-card.component.scss']
})
export class UsAssignmentCardComponent implements OnInit {
  constructor(private service: IntegrationService,
              protected dataService: DataService) {}

  @Input()
  assignment: AssignmentDto | undefined;
  action: AssignmentActionType | undefined;

  @Output()
  showPackageView = new EventEmitter<ShowDetailsWrapper>();

  @Input() brand!: string;
  @Input() theme!: string;

  modalIsOpen = false;

  // calling this will open the modal
  openMethod(action: string) {
    if(this.disableButtons) {
      if ('WITHDRAW' === action) this.action = AssignmentActionType.WITHDRAW;
      else if ('REJECT' === action) this.action = AssignmentActionType.REJECT;
      else if ('APPROVE' === action) this.action = AssignmentActionType.APPROVE;
      this.modalIsOpen = true;
    } else {
      console.log("Action Not Allowed!")
    }
  }

  // calling this will dismiss the modal
  closeModal() {
    this.modalIsOpen = false;
  }

  changeAssignment() {
    if (this.assignment && this.assignment.gcid) {
      const gcid = this.assignment.gcid;

      let status: AssignmentStatus;
      switch (this.action) {
        case AssignmentActionType.APPROVE:
          status = AssignmentStatus.ACCEPTED;
          break;
        case AssignmentActionType.REJECT:
          status = AssignmentStatus.REJECTED;
          break;
        case AssignmentActionType.WITHDRAW:
          status = AssignmentStatus.REVOKED_BY_CUSTOMER;
          break;
        default:
          throw new Error('Unsupported action: ' + this.action);
      }

      this.service
        .changeAssignmentStatus(this.assignment.vin, gcid, status)
        .subscribe((_ignore) => {
          this.action = undefined;
          this.service.getAssignment(gcid).subscribe((val) => {
            if (val) {
              this.assignment = val;
              this.service.redirectBackTo(
                !this.assignment.assignmentUuid ? this.assignment.id : this.assignment.assignmentUuid,
                this.assignment.status,
                ViewSource.ASSIGNMENT
              );
            }
          });
          this.closeModal();
        });
    }
  }

  get showAcceptButton() {
    return [
      'REQUESTED',
      'REJECTED',
      'REVOKED',
      'TIMED_OUT',
      'WAIT_FOR_RENEWAL'
    ].includes(this.assignment?.status || '');
  }

  get showRejectButton() {
    return ['REQUESTED', 'TIMED_OUT', 'WAIT_FOR_RENEWAL'].includes(
      this.assignment?.status || ''
    );
  }

  get disableButtons(){
    if(this.assignment){
      document.getElementById('accept')?.setAttribute('disabled', String(this.assignment.gcid == this.dataService.gcId));
      document.getElementById('accept')?.setAttribute('disabled', String(this.assignment.gcid == this.dataService.gcId));
      return this.assignment.gcid == this.dataService.gcId;
    }
    document.getElementById('accept')?.setAttribute('disabled', String(true));
    document.getElementById('accept')?.setAttribute('disabled', String(true));
    return true;
  }

  showPackageDetailView() {
    const wrapper: ShowDetailsWrapper = {
      source: ViewSource.CLEARANCE,
      shouldShow: true
    };
    this.showPackageView.emit(wrapper);
  }

  ngOnInit(): void {}

  protected readonly open = open;
}
