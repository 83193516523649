<div class="theme-bmw clearance-card neo-ds BMW">
  <div class="clearance-card-header content-headline-s">
    {{ clearance?.companyName }}
  </div>
  <div class="clearance-table">
    <div class="row">
      <div class="col">{{ 'clearance.card.requestOf' | translate }}</div>
      <div class="col">
        {{ clearance?.requestDate | date: 'YYYY-MM-d' }}
      </div>
    </div>
    <div class="row">
      <div class="col">{{ 'clearance.card.status' | translate }}</div>
      <div class="col" style="color: #1976d2; font-weight: bold">
        {{ clearance?.status }}
      </div>
    </div>
    <div class="row">
      <div class="col">{{ 'clearance.card.statusChanged' | translate }}</div>
      <div class="col">
        {{ clearance?.modifiedDate | date: 'YYYY-MM-d' }}
      </div>
    </div>
    <div class="row" (click)="showPackageDetailView()" style="cursor: pointer">
      <div class="col">{{ 'clearance.card.dataPackage' | translate }}</div>
      <div class="col" style="font-weight: bold">
        <i class="wi wi-arrow-chevron-right"></i>
        <span class="truncate" id="name">
          {{clearance?.containerName }}
        </span>
        <neo-tooltip id="tooltip" brand="BMW" heading="" trigger="hover" position="auto" target="name" variant="tooltip">

        </neo-tooltip>
      </div>
    </div>
  </div>
  <div class="neo-ds BMW clearance-buttons">
    <button
      [disabled]="!showAcceptButton || disableButtons"
      class="neo-primary-btn"
      (click)="acceptClearanceNotify()">
      {{ 'buttons.general.accept' | translate }}
    </button>
    <button
      [disabled]="!showRejectButton || disableButtons"
      class="neo-tertiary-btn"
      (click)="rejectClearance()"
    >
      {{ 'buttons.general.reject' | translate }}
    </button>
  </div>
</div>

  <ds-modal
    #tpl
    [ds-modal-trigger-for]="tpl"
    [(isModalOpen)]="modalIsOpen"
    size="sm"
    style="color: black;"
  >
    <ds-box-header><h1>{{ 'modal.header' | translate }}</h1></ds-box-header>
    <ds-box-content style="margin-bottom: 5rem;">
      {{'modal.content'
      | translate
      | replace: '{DATA_PACKAGE}':clearance?.containerName
      | replace: '{COMPANY_NAME}':clearance?.companyName }}</ds-box-content>
    <ds-box-footer>
      <button
        class="neo-tertiary-btn"
        type="reset"
        (click)="closeModal()"
      >
        {{ 'modal.reject' | translate }}
      </button>
      <button
        class="neo-primary-btn"
        type="submit"
        (click)="acceptClearance()"
      >
        {{ 'modal.confirm' | translate }}
      </button>
    </ds-box-footer>
  </ds-modal>
