import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectListOption} from '@bmw-ds/components';
import {ActivatedRoute} from '@angular/router';
import "@neo-ds/components/input-dropdown";

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  selectedLanguage = this.translate.defaultLang;
  localeOptions: SelectListOption[] = [
    {id: 'en', label: 'EN'},
    {id: 'de', label: 'DE'},
    {id: 'fr', label: 'FR'},
    {id: 'hu', label: 'HU'},
    {id: 'it', label: 'IT'},
    {id: 'nl', label: 'NL'},
    {id: 'no', label: 'NO'},
    {id: 'pl', label: 'PL'},
    {id: 'pt', label: 'PT'},
    {id: 'ro', label: 'RO'},
    {id: 'sk', label: 'SK'},
    {id: 'sl', label: 'SL'},
    {id: 'sv', label: 'SV'},
    {id: 'bg', label: 'BG'},
    {id: 'cs', label: 'CS'},
    {id: 'da', label: 'DA'},
    {id: 'el', label: 'EL'},
    {id: 'es', label: 'ES'},
    {id: 'fi', label: 'FI'}
  ];

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {
  }

  @Output()
  menuToggled = new EventEmitter<string>();

  ngOnInit(): void {
    console.log('reloading....');
    if (this.translate.currentLang) {
      this.selectedLanguage = this.translate.currentLang;
    }
    this.route.queryParams.subscribe((params: any) => {
      if (params.language) {
        this.selectedLanguage = params.language;
        this.translate.use(params.language);
      }
    });
  }

  handleLangChange = (value: string) => {
    this.selectedLanguage = value?.toLowerCase();
    this.translate.use(value?.toLowerCase());
    this.menuToggled.emit(value);
  };
}
