<div class="app-group-header app-group-main-navigation">
  <div class="component__fullwidth__inner header__wrapper container">
    <div class="logo_menu">
      <a [routerLink]="'/home'" class="header__logolink">
        <img
          class="header__logo"
          src="assets/images/mini-logo.png"
          alt="Logo"
        />
      </a>
      <div
        (mouseenter)="handleMouseEnter()"
        (mouseover)="handleMouseOver()"
        [class.collapse]="collapse"
        class="component__fullwidth"
      >
        <div class="component__fullwidth__inner">
          <div
            #menu
            [class.opened]="menuState"
            class="mainnavigation__wrapper"
            data-component-name="desktopflyout"
          >
            <ul
              #list
              [class.list-collapse]="collapse"
              class="mainnavigation__ulist"
            >
              <li
                *ngFor="let item of menuItems | callbackFilter: showMenu"
                class="mainnavigation__list"
              >
                <a
                  (click)="handleMenuClicked(item)"
                  *ngIf="item.children && item.children.length > 0 && showMenu(item)"
                  [class.mainnavigation__item--active]="item.active"
                  [class.mainnavigation__item]="!homeSelected"
                  [class.mainnavigationhome__item]="homeSelected"
                  [ngStyle]="{ 'border-bottom-color': '#006583' }"
                >
            <span [class.item-collapse]="collapse">
              {{ item.label | translate }}</span
            >
                </a>

                <a
                  (click)="handleMenuClicked(item)"
                  *ngIf="
              showMenu(item) && (!item.children || item.children.length === 0)
            "
                  [class.mainnavigation__item]="!homeSelected"
                  [class.mainnavigationhome__item]="homeSelected"
                  [ngStyle]="{ 'border-bottom-color': '#006583' }"
                  [routerLink]="item.url"
                  routerLinkActive="grp-mainnavigation__item--active"
                >
            <span [class.grp-item-collapse]="collapse">
              {{ item.label | translate }}</span
            >
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="header__servicebar">
      <app-language
        *ngIf="!environment.isUSHub"
        (menuToggled)="toggleMenu($event)"
      ></app-language>
    </div>
  </div>
</div>
