import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClearanceDto } from '../../../dtos/ClearanceDto';
import { ClearanceStatus } from '../../../dtos/ClearanceStatus';
import { IntegrationService } from '../../../services/integration/integration.service';
import { DataService } from '../../../services/data.service';
import { ShowDetailsWrapper } from '../../../dtos/ShowDetailsWrapper';
import { ViewSource } from '../../../dtos/ViewSource';

@Component({
  selector: 'app-us-clearance-card[clearance]',
  templateUrl: './us-clearance-card.component.html',
  styleUrls: ['./us-clearance-card.component.scss']
})
export class UsClearanceCardComponent implements OnInit {
  constructor(
    private service: IntegrationService,
    protected dataService: DataService
  ) {}

  @Input()
  clearance: ClearanceDto | undefined;

  @Output()
  showPackageView = new EventEmitter<ShowDetailsWrapper>();

  @Input() brand!: string;
  @Input() theme!: string;

  modalIsOpen = false;

  // calling this will open the modal
  openMethod() {
    this.modalIsOpen = true;
  }

  // calling this will dismiss the modal
  closeModal() {
    this.modalIsOpen = false;
  }

  acceptClearanceNotify() {
    this.openMethod();
  }
  acceptClearance() {
    this.changeClearance(ClearanceStatus.APPROVED);
  }

  rejectClearance() {
    this.changeClearance(ClearanceStatus.REJECTED);
  }

  changeClearance(status: ClearanceStatus) {
    if (this.clearance?.vin && this.clearance?.gcid) {
      this.service
        .changeClearanceStatus(this.clearance.vin, this.clearance.gcid, status)
        .subscribe((_ignore) => {
          if (this.clearance) this.clearance.status = status;
          this.service.redirectBackTo(
            this.dataService.clearanceId,
            status,
            ViewSource.CLEARANCE
          );
        });
    }
  }

  get showAcceptButton() {
    return [
      'REQUESTED',
      'REJECTED',
      'REVOKED',
      'TIMED_OUT',
      'WAIT_FOR_RENEWAL'
    ].includes(this.clearance?.status || '');
  }

  get disableButtons(){
    if (this.clearance) {
      const gcidsDiffer = this.clearance.gcid !== this.dataService.gcId;
      document.getElementById('accept')?.setAttribute('disabled', String(gcidsDiffer));
      document.getElementById('reject')?.setAttribute('disabled', String(gcidsDiffer));
      return gcidsDiffer;
    }
    return true;
  }

  get showRejectButton() {
    return ['REQUESTED', 'TIMED_OUT', 'WAIT_FOR_RENEWAL'].includes(
      this.clearance?.status || ''
    );
  }

  showPackageDetailView() {
    const wrapper: ShowDetailsWrapper = {
      source: ViewSource.CLEARANCE,
      shouldShow: true
    };
    this.showPackageView.emit(wrapper);
  }

  ngOnInit(): void {
    console.log("us-userGCID", this.dataService.gcId);
  }
}
