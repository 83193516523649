import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClearanceDto } from '../../../dtos/ClearanceDto';
import { TelematicKeyDto } from '../../../dtos/TelematicKeyDto';
import { PackageDetailsDto } from '../../../dtos/PackageDetailsDto';
import { AssignmentDto } from '../../../dtos/AssignmentDto';
import { ShowDetailsWrapper } from '../../../dtos/ShowDetailsWrapper';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-package-details-us[details]',
  templateUrl: './us-package-details.component.html',
  styleUrls: ['./us-package-details.component.scss']
})
export class UsPackageDetailsComponent implements OnInit {
  @Input()
  set details(value: PackageDetailsDto | undefined) {
    if (value) {
      this.detailsInfo = value;
      if (value.clearance) {
        this.clearanceInfo = value.clearance;
        if (value.clearance.telematicKeys)
          this.keysFiltered = [...value.clearance.telematicKeys.slice(0, 3)];
      }
      if (value.assignment) {
        this.assignmentInfo = value.assignment;
      }
    }
  }

  @Output()
  showPackageView = new EventEmitter<ShowDetailsWrapper>();

  expanded = false;

  keysFiltered: TelematicKeyDto[] = [];
  clearanceInfo?: ClearanceDto = {} as ClearanceDto;
  assignmentInfo?: AssignmentDto = {} as AssignmentDto;
  detailsInfo: PackageDetailsDto = {} as PackageDetailsDto;
  @Input() brand!: string;
  @Input() theme!: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    // US is only english.
    this.translateService.use('en');
  }

  hidePackageDetailView() {
    this.showPackageView.emit({
      source: undefined,
      shouldShow: false
    });
  }

  expandDetails() {
    this.expanded = true;
    if (this.clearanceInfo)
      this.keysFiltered = [...this.clearanceInfo.telematicKeys];
  }

  collapseDetails() {
    this.expanded = false;
    if (this.clearanceInfo)
      this.keysFiltered = [...this.clearanceInfo.telematicKeys.slice(0, 3)];
  }
}
