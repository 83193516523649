<div *ngIf="submenu === 0" class="menu-header row">
  <div class="col">
    <span
      (click)="handleCloseMenuClick()"
      class="iwp-icon-gen_close close"
    ></span>
  </div>
</div>

<div class="row" style="margin: 20px">
  <div class="col">
    <ul
      [class.opened]="opened"
      [ngClass]="'submenu-' + submenu"
      [ngStyle]="{
        'border-left':
          '2px solid ' + (parent && parent.color ? parent.color : '')
      }"
    >
      <li
        *ngFor="let item of items | callbackFilter: showMenu"
        [ngStyle]="{
          'border-left':
            '5px solid ' + (parent && parent.color ? parent.color : '')
        }"
      >
        <div
          (click)="toggleItem(item, $event)"
          *ngIf="item.children && item.children.length > 0 && showMenu(item)"
          [class.menu--active-parent]="item.active"
          [class.opened]="item.opened"
          class="parent-item"
        >
          <div class="parent-item--value">{{ item.label | translate }}</div>
          <app-menu
            (navigationEvent)="navigation($event)"
            *ngIf="item.opened && showMenu(item)"
            [items]="item.children"
            [parent]="item"
            [submenu]="submenu + 1"
          ></app-menu>
        </div>
        <a
          (click)="navigation($event)"
          *ngIf="
            (!item.children || item.children.length === 0) && showMenu(item)
          "
          [routerLink]="[item.url]"
          routerLinkActive="menu--active-item"
          >{{ item.label | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>
