import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuItem } from '../../models/menu-item';
import { MenuService } from '../../../services/menu/menu.service';
import { EnvironmentConfigService } from '../../environment-config/environment-config.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements AfterViewInit, OnDestroy {
  @Input()
  items: MenuItem[] | undefined;
  @Input()
  parent: MenuItem | undefined;
  @Input()
  submenu = 0;
  opened = true;
  @Output()
  navigationEvent = new EventEmitter<any>();
  @Output()
  closeMenuEvent = new EventEmitter<any>();
  private subscriptions: Subscription[] = [];

  constructor(
    private menuService: MenuService,
    public environment: EnvironmentConfigService
  ) {
    this.subscriptions.push(
      this.menuService.urlChange.subscribe(() => {
        this.setActiveParent();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.setActiveParent();
  }

  showMenu(menu: MenuItem): boolean {
    return menu.show ? menu.show() : true;
  }

  setActiveParent() {
    if (!this.items) {
      return;
    }

    Promise.resolve().then(() => {
      const url = this.menuService.getCurrentUrl();
      this.items?.forEach((item) => {
        const regexp = new RegExp(item.url + '.*', 'g');
        if (item.children && url.match(regexp)) {
          item.opened = true;
          item.active = true;
        } else {
          item.opened = false;
          item.active = false;
        }
      });
    });
  }

  navigation(event: { stopPropagation: () => void }) {
    this.navigationEvent.emit(event);
    event.stopPropagation();
  }

  toggleItem(clickedItem: MenuItem, event: Event) {
    this.items?.forEach((item) => {
      if (item === clickedItem) {
        item.opened = !item.opened;
      } else {
        item.opened = false;
      }
    });
    event.stopPropagation();
  }

  handleCloseMenuClick() {
    if (this.closeMenuEvent) {
      this.closeMenuEvent.emit(true);
    }
  }
}
