import { EventEmitter, Injectable, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '../../components/models/menu-item';
import { MenuFilter } from '../../components/models/menu-filter';

export const enum AppPaths {
  Home = 'home',
  Login = 'login',
  Logout = 'logout'
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  @Output()
  urlChange = new EventEmitter<string>();

  menus: MenuItem[] = [
    {
      level: 1,
      url: `/`,
      label: 'button.home',
      icon: 'home',
      skipInFooter: true,
      color: '#1C69D4',
      children: []
    }
  ];

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.urlChange.emit(this.router.url);
      }
    });
  }

  getCurrentUrl(): string {
    return this.router.url;
  }

  getMenuItems(filter?: MenuFilter): MenuItem[] {
    let items = this.menus;
    if (!filter) {
      return items;
    }

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        items = items.filter((item) => {
          // @ts-ignore
          return !item[key];
        });
      }
    }

    return items;
  }

  searchMenuItemByUrl(
    wantedItem: string,
    array: MenuItem[]
  ): MenuItem[] | undefined | null {
    for (const item of array) {
      if (item.url === '/' + wantedItem) {
        return item.children;
      }

      if (item.children) {
        const childrenMatch = this.searchMenuItemByUrl(
          wantedItem,
          item.children
        );
        if (childrenMatch) {
          return childrenMatch;
        }
      }
    }

    return null;
  }
}
