import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClearanceDto } from '../../../dtos/ClearanceDto';
import { ClearanceStatus } from '../../../dtos/ClearanceStatus';
import { IntegrationService } from '../../../services/integration/integration.service';
import { DataService } from '../../../services/data.service';
import { ShowDetailsWrapper } from '../../../dtos/ShowDetailsWrapper';
import { ViewSource } from '../../../dtos/ViewSource';
import "@neo-ds/components/button";
import "@neo-ds/components/tooltip";

@Component({
  selector: 'app-group-clearance-card[clearance]',
  templateUrl: './group-clearance-card.component.html',
  styleUrls: ['./group-clearance-card.component.scss']
})
export class GroupClearanceCardComponent implements OnInit {
  constructor(
    private service: IntegrationService,
    private dataService: DataService
  ) {}

  @Input()
  clearance: ClearanceDto | undefined;

  @Output()
  showPackageView = new EventEmitter<ShowDetailsWrapper>();

  modalIsOpen = false;

  // calling this will open the modal
  openMethod() {
    this.modalIsOpen = true;
  }

  // calling this will dismiss the modal
  closeModal() {
    this.modalIsOpen = false;
  }

  acceptClearanceNotify() {
    this.openMethod();
  }
  acceptClearance() {
    this.changeClearance(ClearanceStatus.APPROVED);
  }

  rejectClearance() {
    this.changeClearance(ClearanceStatus.REJECTED);
  }

  changeClearance(status: ClearanceStatus) {
    this.closeModal();
    if (this.clearance?.vin && this.clearance?.gcid) {
      console.log(
        `clearance change info: ${this.clearance.vin}, ${this.clearance.gcid}, ${status}`
      );
      this.service
        .changeClearanceStatus(this.clearance.vin, this.clearance.gcid, status)
        .subscribe((_ignore) => {
          if (_ignore && 'error' in _ignore) {
            this.changeClearance(status);
            return;
          }
          if (this.clearance) this.clearance.status = status;
          this.service.redirectBackTo(
            this.dataService.clearanceId,
            status,
            ViewSource.CLEARANCE
          );
        });
    }
  }

  get showAcceptButton() {
    return [
      'REQUESTED',
      'REJECTED',
      'REVOKED',
      'TIMED_OUT',
      'WAIT_FOR_RENEWAL'
    ].includes(this.clearance?.status || '');
  }

  get disableButtons(){
    if(this.clearance){
      return this.clearance.gcid !== this.dataService.gcId;
    }
    return false;
  }

  get showRejectButton() {
    return ['REQUESTED', 'TIMED_OUT', 'WAIT_FOR_RENEWAL'].includes(
      this.clearance?.status || ''
    );
  }

  showPackageDetailView() {
    const wrapper: ShowDetailsWrapper = {
      source: ViewSource.CLEARANCE,
      shouldShow: true
    };
    this.showPackageView.emit(wrapper);
  }

  ngOnInit(): void {
    console.log("group-userGCID", this.dataService.gcId);
    document.getElementById('tooltip')?.setAttribute('heading', this.clearance?.containerName || '');
  }
}
