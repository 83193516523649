<div
  [class]="'neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + theme"
  style="background: #fff; padding: 35px; margin: 0 10px; border-radius: 5px"
>
  <div class="row" style="padding: 5px">
    <div
      class="col"
      style="font-weight: bold; border-bottom: 1px #ebebeb solid"
    >
      Date Requested
    </div>
    <div
      class="col"
      style="font-weight: bold; border-bottom: 1px #ebebeb solid"
    >
      Company
    </div>
    <div
      class="col"
      style="font-weight: bold; border-bottom: 1px #ebebeb solid"
    >
      Data Package
    </div>
    <div
      class="col"
      style="font-weight: bold; border-bottom: 1px #ebebeb solid"
    >
      Status
    </div>
    <div
      class="col"
      style="font-weight: bold; border-bottom: 1px #ebebeb solid"
    >
      Changed on
    </div>
    <div
      class="col"
      style="font-weight: bold; border-bottom: 1px #ebebeb solid"
    >
      Your Options
    </div>
  </div>
  <div class="row">
    <div class="col" style="margin: 5px">
      {{ assignment?.createdAt | date: 'YYYY-MM-d' }}
    </div>
    <div class="col" style="margin: 5px">{{ '' }}</div>
    <div class="col" style="margin: 5px">
      <a
        routerLink=""
        (click)="showPackageDetailView()"
        style="color: #333333; cursor: pointer"
        >{{
          'assignment.package-details.request.' +
            assignment?.serviceType +
            '.name' | translate
        }}</a
      >
    </div>
    <div class="col" style="margin: 5px">
      {{ assignment?.status }}
    </div>
    <div class="col" style="margin: 5px">
      {{ assignment?.updatedAt | date: 'YYYY-MM-d' }}
    </div>
    <div class="col" style="margin: 5px">
      <a id="accept"
        routerLink="" (click)="!disableButtons ? openMethod('APPROVE') : null"
         [style]="disableButtons ? 'cursor: not-allowed; color: #cecece !important;' : 'cursor: pointer'"
      >{{ 'buttons.general.accept' | translate }}</a>&nbsp;
      <a id="reject"
        routerLink="" (click)="!disableButtons ? openMethod('REJECT') : null"
        [style]="disableButtons ? 'cursor: not-allowed; color: #cecece !important;' : 'cursor: pointer'"
      >{{ 'buttons.general.reject' | translate }}</a>
    </div>
  </div>
</div>

<ds-modal
  #tpl
  [ds-modal-trigger-for]="tpl"
  [(isModalOpen)]="modalIsOpen"
  size="sm"
  style="color: black;"
>
  <ds-box-header>
    <h1 [style]="brand !== 'MINI' ? 'font-family: BMWTypeNext Pro, sans-serif !important;' : 'font-family: MINIType v2 Regular, sans-serif !important;'"
        style="font-family: 'BMWTypeNext Pro',sans-serif; font-weight: 400;">Approve BMW CarData Access?</h1></ds-box-header>
  <ds-box-content [style]="brand !== 'MINI' ? 'font-family: BMWTypeNext Pro, sans-serif !important;' : 'font-family: MINIType v2 Regular, sans-serif !important;'"
                  style="margin-bottom: 5rem; font-family: 'BMWTypeNext Pro', sans-serif">
    <b>Please note:</b><br />{{
      'You hereby agree to the request for data access for {DATA_PACKAGE}. Should you remove the vehicle from your account or add it to another account, the data access for {DATA_PACKAGE} will no longer be possible from that point forward. BMW of North America, LLC can help me disclose my BMW CarData information to the third party of your choice in order to provide me with offers and services'
        | translate: { DATA_PACKAGE: assignment?.serviceType }
        | replace: '{DATA_PACKAGE}':''
        | replace: '{COMPANY_NAME}':''
    }}</ds-box-content>
  <ds-box-footer style="font-family: 'BMWTypeNext Pro', sans-serif;">
    <button
      class="neo-tertiary-btn"
      type="reset"
      [style]="brand !== 'MINI' ? 'font-family: BMWTypeNext Pro, sans-serif !important;' : 'font-family: MINIType v2 Regular, sans-serif !important;'"
      (click)="closeModal()"
    >
      {{ 'modal.reject' | translate }}
    </button>
    <button
      class="neo-primary-btn"
      type="submit"
      [style]="brand !== 'MINI' ? 'background-color: #1976d2 !important; border: 1px solid #1976d2 !important; font-family: BMWTypeNext Pro, sans-serif !important;' : 'background-color: #006583 !important; border: 1px solid #006583 !important; font-family: MINIType v2 Regular, sans-serif !important;'"
      (click)="changeAssignment()"
    >
      {{ 'modal.confirm' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
