import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService } from '../../../../../services/menu/menu.service';
import { MenuItem } from '../../../../models/menu-item';
import { EnvironmentConfigService } from '../../../../environment-config/environment-config.service';

@Component({
  selector: 'app-mini-main-navigation',
  templateUrl: './mini-main-navigation.component.html',
  styleUrls: ['./mini-main-navigation.component.scss']
})
export class MiniMainNavigationComponent implements OnInit, OnDestroy {
  @Output()
  menuToggled = new EventEmitter<boolean>();
  @Output()
  closeMenu = new EventEmitter<boolean>();
  menuState: boolean = false;
  search: boolean = false;
  private subscriptions: Subscription[] = [];
  @Output()
  menuSelected = new EventEmitter<boolean>();
  @ViewChild('menu', { static: false })
  menu: any;
  @ViewChild('list', { static: false })
  list: any;
  menuItems: MenuItem[];
  delta = 20;
  lastScrollTop = 0;
  collapse = false;
  homeSelected = true;

  constructor(
    public menuService: MenuService,
    public environment: EnvironmentConfigService
  ) {
    this.menuItems = this.menuService.getMenuItems({ skipInHeader: true });
    this.subscriptions.push(
      this.menuService.urlChange.subscribe(() => {
        this.setActiveItem();
        this.setActiveParent();
        this.menuState = false;
      })
    );
    this.subscriptions.push(
      this.menuService.urlChange.subscribe(() => {
        this.menuState = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.setActiveItem();
    this.setActiveParent();
  }

  toggleMenu(event?: any): void {
    Promise.resolve().then(() => {
      this.menuState = !this.menuState;
      this.menuToggled.emit(this.menuState);
    });
  }

  handelCloseMenu() {
    if (this.closeMenu) {
      this.closeMenu.emit(true);
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: { currentTarget: { scrollY: any } }) {
    this.hasScrolled(event.currentTarget.scrollY);
  }

  hasScrolled(scrollY: any) {
    const st = scrollY;

    if (Math.abs(this.lastScrollTop - st) <= this.delta) {
      return;
    }

    this.collapse = st > this.lastScrollTop;
    this.lastScrollTop = st;
  }

  handleMouseOver() {
    this.collapse = false;
  }

  handleMouseEnter() {
    this.collapse = false;
  }

  handleMenuClicked(menu: any) {
    if (this.menuSelected) {
      this.menuSelected.emit(menu);
    }
  }

  showMenu(menu: MenuItem): boolean {
    return menu.show ? menu.show() : true;
  }

  setActiveParent() {
    if (!this.menuItems) {
      return;
    }

    Promise.resolve().then(() => {
      this.homeSelected = true;
      const url = this.menuService.getCurrentUrl();
      this.menuItems.forEach((item) => {
        const regexp = new RegExp(item.url + '.*', 'g');
        item.active = !!(item.children && url.match(regexp));
      });
    });
  }

  setActiveItem() {
    setTimeout(() => {
      const activeItem = this.menu.nativeElement.querySelector(
        '.mini-mainnavigation__item--active'
      );
      if (!activeItem) {
        return;
      }

      // if active item is hidden on the left, scroll that direction
      if (activeItem.getBoundingClientRect().left < 0) {
        const position = this.menu.nativeElement.scrollLeft;
        const diff = position + activeItem.getBoundingClientRect().left;
        this.menu.nativeElement.scrollTo(diff, 0);
        return;
      }

      // if active item is hidden on the right, scroll that direction
      const width = this.menu.nativeElement.getBoundingClientRect().width;
      const right = activeItem.getBoundingClientRect().right;
      if (right > width) {
        const diff = right - width;
        this.menu.nativeElement.scrollTo(diff, 0);
      }
    });
  }
}
