<div class="rectangle theme-mini">
  <div>
    <neo-loader color="PRIMARY" size="50" brand="MINI" center=""
                *ngIf="!vehicleData && !errorMsg">
    </neo-loader>
  </div>
  <div class="page-wrapper container">
    <div *ngIf="imageUrl && !errorMsg" class="vehicle-image">
      <img src="{{ imageUrl.url }}" alt="Vehicle Image" />
    </div>
    <div *ngIf="vehicleData || errorMsg">
        <div class="vehicle-name" *ngIf="!errorMsg">
          {{ vehicleData?.modelName }}
        </div>
        <div class="vin" *ngIf="!errorMsg">
          {{ 'vehicle.details.vin' | translate }}
          {{ vehicleData?.vin }}
        </div>
        <p *ngIf="errorMsg" [innerHTML]="errorMsg" style="padding: 15px"></p>
    </div>
  </div>
</div>
