import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {
  DsBannerModule,
  DsButtonModule,
  DsCalendarModule,
  DsFormFieldModule,
  DsIconModule,
  DsMenuModule,
  DsModalModule,
  DsProgressCircleModule,
  DsSelectModule,
  DsTooltipDirectiveModule
} from '@bmw-ds/components';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HeaderComponent} from '../components/header/header.component';
import {MenuDropdownComponent} from '../components/header/menu-dropdown/menu-dropdown.component';
import {MenuComponent} from '../components/header/menu/menu.component';
import {CallbackFilterPipe} from '../components/pipes/callback-filter/callback-filter.pipe';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {IntegrationService} from '../services/integration/integration.service';
import {GroupVehicleDetailsComponent} from '../components/vehicle-details/group/group-vehicle-details.component';
import {
  GroupClearanceCardComponent
} from '../components/clearance-card/group-clearance-card/group-clearance-card.component';
import {ClearanceModalComponent} from '../components/clearance-modal/clearance-modal.component';
import {GroupHeaderComponent} from '../components/header/brand-headers/group/group-header/group-header.component';
import {LanguageComponent} from '../components/language/language.component';
import {FormsModule} from '@angular/forms';
import {
  EmeaPackageDetailsComponent
} from '../components/package-details/emea-package-details/emea-package-details.component';
import {Nl2pbrPipe} from '../components/pipes/nl2pbr.pipe';
import {ReplacePipe} from '../components/pipes/replace.pipe';
import {UsClearanceCardComponent} from '../components/clearance-card/us-clearance-card/us-clearance-card.component';
import {
  BmwMainNavigationComponent
} from '../components/header/brand-headers/bmw/bmw-main-navigation/bmw-main-navigation.component';
import {BmwClearanceCardComponent} from '../components/clearance-card/bmw-clearance-card/bmw-clearance-card.component';
import {
  MiniClearanceCardComponent
} from '../components/clearance-card/mini-clearance-card/mini-clearance-card.component';
import {
  MiniMainNavigationComponent
} from '../components/header/brand-headers/mini/mini-main-navigation/mini-main-navigation.component';
import {MiniVehicleDetailsComponent} from '../components/vehicle-details/mini/mini-vehicle-details.component';
import {UsVehicleDetailsComponent} from '../components/vehicle-details/us/us-vehicle-details.component';
import {UsPackageDetailsComponent} from '../components/package-details/us-package-details/us-package-details.component';
import {
  BmwAssignmentCardComponent
} from '../components/assignment-card/bmw-assignment-card/bmw-assignment-card.component';
import {AssignmentModalComponent} from '../components/assignment-modal/assignment-modal.component';
import {UsAssignmentCardComponent} from '../components/assignment-card/us-assignment-card/us-assignment-card.component';
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import { FooterComponent } from '../components/footer/footer.component';
import {NgOptimizedImage} from "@angular/common";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuDropdownComponent,
    MenuComponent,
    CallbackFilterPipe,
    GroupVehicleDetailsComponent,
    GroupClearanceCardComponent,
    UsClearanceCardComponent,
    ClearanceModalComponent,
    AssignmentModalComponent,
    BmwAssignmentCardComponent,
    GroupHeaderComponent,
    LanguageComponent,
    EmeaPackageDetailsComponent,
    Nl2pbrPipe,
    ReplacePipe,
    BmwMainNavigationComponent,
    BmwClearanceCardComponent,
    MiniClearanceCardComponent,
    MiniMainNavigationComponent,
    MiniVehicleDetailsComponent,
    UsVehicleDetailsComponent,
    UsPackageDetailsComponent,
    UsAssignmentCardComponent,
    UsAssignmentCardComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    DsProgressCircleModule,
    DsButtonModule,
    DsIconModule,
    DsModalModule,
    DsMenuModule,
    DsSelectModule,
    DsFormFieldModule,
    FormsModule,
    DsTooltipDirectiveModule,
    DsCalendarModule,
    DsBannerModule,
    MatOption,
    MatSelect,
    FooterComponent,
    NgOptimizedImage
  ],
  providers: [HttpClient, IntegrationService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
