<div [class]="'container neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + theme">
  <div class="back" (click)="hidePackageDetailView()">
    @if(brand !== 'MINI'){
      <i class="wi wi-arrow-chevron-left"></i>
    }
    @else {
      <i class="wi wi-angle-left"></i>
    }
    <span>{{ 'buttons.general.back' | translate }}</span>
  </div>
</div>
<div *ngIf="detailsInfo.source === 'CLEARANCE'" [class]="'neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + theme">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="cardata---detail">
          {{ 'clearance.package-details.title' | translate }}<br/>
          {{ clearanceInfo?.companyName }}
        </div>
        <div class="here--you-can-find-d">
          {{
          'clearance.package-details.content'
            | translate
            | replace: '{COMPANY_NAME}':clearanceInfo?.companyName
          }}
        </div>
        <div class="personal-message-container">
          <div class="personal-message-title">
            {{ 'clearance.package-details.personal-message.title' | translate }}
          </div>
          <div class="personal-message-content">
            {{ clearanceInfo?.clearanceRequestMessage }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="contact-info">
          <div class="applicant-bmw-ag---c">
            <div>
              {{ 'clearance.package-details.applicant-info.title' | translate }}
            </div>
            <div>{{ clearanceInfo?.companyName }}</div>
            <div>{{ clearanceInfo?.companyAddress }}</div>
            <br/>
            <div>
              {{ 'clearance.package-details.applicant-info.contact-details.title' | translate }}
            </div>
            <div>{{ clearanceInfo?.contactName }}</div>
            <div>{{ clearanceInfo?.contactPhone }}</div>
            <div>{{ clearanceInfo?.contactEmail }}</div>
          </div>
        </div>
        <div class="please-note--the-app">
          {{ 'clearance.package-details.notice' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div [class]="'container neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + theme">
    <div class="details-on-the-reque">
      {{ 'clearance.package-details.request.title' | translate }}
    </div>
  </div>
  <div [class]="'container neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW')">
    <div class="row">
      <div class="col-2 tkeys-header">
        {{
        'clearance.package-details.request.key-headers.item-id' | translate
        }}
      </div>
      <div class="col-5 tkeys-header">
        {{
        'clearance.package-details.request.key-headers.cardata-item'
          | translate
        }}
      </div>
      <div class="col-5 tkeys-header">
        {{
        'clearance.package-details.request.key-headers.description'
          | translate
        }}
      </div>
    </div>
    <div class="row tkeys-row" *ngFor="let tkey of keysFiltered; let i = index">
      <div class="col-2 align-self-center">
        <span>{{ i + 1 }}</span>
      </div>
      <div class="col-5 align-self-center">{{ tkey.name }}</div>
      <div class="col-5 align-self-center" [innerHTML]="tkey.description"></div>
    </div>
  </div>
</div>
<!------>

<div *ngIf="detailsInfo.source === 'ASSIGNMENT'" [class]="'neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + theme">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="cardata---detail">
          {{
          'assignment.package-details.title'
            | translate
            | replace
            : '{SERVICE_NAME}'
              : ('assignment.package-details.request.' +
            assignmentInfo?.serviceType +
            '.name'
              | translate)
            | replace: '{COMPANY_NAME}':assignmentInfo?.companyName | uppercase


          }}<br/>
        </div>
        <div class="here--you-can-find-d">
          {{
          'assignment.package-details.content'
            | translate
            | replace: '{COMPANY_NAME}':assignmentInfo?.customerSupportName
          }}
        </div>
        <div class="personal-message-container">
          <div class="personal-message-title">
            {{
            'assignment.package-details.personal-message.title' | translate
            }}
          </div>
          <div class="personal-message-content">
            {{ assignmentInfo?.message }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="contact-info">
          <div class="applicant-bmw-ag---c">
            <div>
              {{
              'assignment.package-details.applicant-info.title' | translate
              }}
            </div>
            <div>{{ assignmentInfo?.companyName }}</div>
            <div>{{ assignmentInfo?.companyAddress }}</div>
            <br/>
            <div>
              {{
              'assignment.package-details.applicant-info.contact-details.title'
                | translate
              }}
            </div>
            <div>{{ assignmentInfo?.customerSupportName }}</div>
            <div>{{ assignmentInfo?.customerSupportPhone }}</div>
            <div>{{ assignmentInfo?.customerSupportEmail }}</div>
          </div>
        </div>
        <div class="please-note--the-app">
          {{ 'assignment.package-details.notice' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div [class]="'container neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + theme">
    <div class="details-on-the-reque">
      {{
      'assignment.package-details.request.' +
      assignmentInfo?.serviceType +
      '.title' | translate
      }}
    </div>
  </div>

  <!---->

  <div [class]="'container neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + theme">
    <div class="row tkeys-row" style="border: none;">
      <div class="col-4 align-self-center">
        <span>{{
          'assignment.package-details.request.' +
          assignmentInfo?.serviceType +
          '.name' | translate
          }}</span>
      </div>
      <div class="col-8 align-self-center">
        <span>{{
          'assignment.package-details.request.' +
          assignmentInfo?.serviceType +
          '.description' | translate
          }}</span>
      </div>
    </div>
  </div>

  <!---->
</div>

<!------>
<div [class]="'container neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + theme">
  <div
    class="showmore"
    (click)="expandDetails()"
    *ngIf="!expanded && detailsInfo.source !== 'ASSIGNMENT'"
  >
    @if(brand !== 'MINI'){
      <i class="wi wi-arrow-chevron-down"></i>
    }
    @else {
      <i class="wi wi-angle-down"></i>
    }
    <span>{{ 'buttons.general.show-more' | translate }}</span>
  </div>
  <div
    class="showmore"
    (click)="collapseDetails()"
    *ngIf="expanded && detailsInfo.source !== 'ASSIGNMENT'"
  >
    @if(brand !== 'MINI'){
      <i class="wi wi-arrow-chevron-up"></i>
    }
    @else {
      <i class="wi wi-angle-up"></i>
    }
    <span>{{ 'buttons.general.collapse' | translate }}</span>
  </div>
</div>
