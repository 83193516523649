import {Injectable} from '@angular/core';
import {StateService} from './state.service';
import {CookieService} from 'ngx-cookie-service';
import {EnvironmentConfigService} from '../components/environment-config/environment-config.service';
import {addSeconds} from '@bmw-ds/components';
import {TelematicKeyDto} from '../dtos/TelematicKeyDto';

const initialState: DataServiceDto = {
  access_token: null,
  code: null,
  brand: 'bmw',
  language: 'EN',
  country: 'de',
  redirectUrl: null,
  clearanceId: null,
  expiry: addSeconds(new Date(), 3600),
  assignmentUuid: null,
  gcId: null
};

@Injectable({
  providedIn: 'root'
})
export class DataService extends StateService<DataServiceDto> {
  readonly dataObject$ = this.select((state) => state);
  private translatedTelematicKeys: Map<string, TelematicKeyDto[]> | undefined;

  constructor(
    private cookieService: CookieService,
    private envConfigService: EnvironmentConfigService
  ) {
    super(initialState);
  }

  get config() {
    return this.envConfigService;
  }

  set dataObject(val: DataServiceDto) {
    this.setState(val);
  }

  get dataObject(): DataServiceDto {
    return this.state;
  }

  set expiry(expiry: Date) {
    this.setState({expiry: expiry});
    if (expiry) {
      this.setCookieVal(`_expiry`, expiry, true);
    } else {
      this.setCookieVal(`_expiry`);
    }
  }

  get expiry(): Date {
    return this.state.expiry || this.getCookieVal('_expiry');
  }

  get accessToken(): string | null {
    if (this.expiry < new Date()) {
      return null;
    }
    return this.state.access_token || this.getCookieVal('_accessToken');
  }

  set accessToken(access_token: string | null) {
    this.setState({access_token});
    if (access_token) {
      this.setCookieVal(`_accessToken`, access_token, true);
    } else {
      this.setCookieVal(`_accessToken`);
    }
  }

  get brand(): string {
    if (this.state.brand) return this.state.brand;
    return this.getCookieVal(`_brand`);
  }

  set brand(brand: string) {
    this.setCookieVal(`_brand`, brand);
    this.setState({brand});
  }

  get language(): string {
    if (this.state.language) return this.state.language;
    return this.getCookieVal(`_lang`) ? this.getCookieVal(`_lang`) : 'en';
  }

  set language(language: string) {
    this.setCookieVal(`_lang`, language);
    this.setState({language});
  }

  get redirectUrl(): string {
    if (this.state.redirectUrl) return this.state.redirectUrl;
    return this.getCookieVal(`_redirectUrl`);
  }

  set redirectUrl(redirectUrl: string) {
    this.setCookieVal(`_redirectUrl`, redirectUrl);
    this.setState({redirectUrl});
  }

  get country(): string {
    if (this.state.country) return this.state.country;
    return this.getCookieVal(`_country`);
  }

  set country(country: string) {
    this.setCookieVal(`_country`, country);
    this.setState({country});
  }

  /**
   * For backwards compatibility
   */
  get assignmentId(): string {
    return this.assignmentUuid;
  }

  /**
   * For backwards compatibility
   */
  set assignmentId(assignmentId: string) {
    this.assignmentUuid = assignmentId;
  }

  /**
   * Could be either assignmentId or assignmentUuid
   */
  get assignmentUuid(): string {
    if (this.state.assignmentUuid) return this.state.assignmentUuid;
    return this.getCookieVal(`_assignmentId`); // Left on _assignmentId for backwards compatibility
  }

  /**
   * @param assignmentUuid Could be either assignmentId or assignmentUuid
   */
  set assignmentUuid(assignmentUuid: string) {
    this.setCookieVal(`_assignmentId`, assignmentUuid, true); // Left on _assignmentId for backwards compatibility
    this.setState({assignmentUuid});
  }

  get clearanceId(): string {
    if (this.state.clearanceId) return this.state.clearanceId;
    return this.getCookieVal(`_clearanceId`);
  }

  set clearanceId(clearanceId: string) {
    this.setCookieVal(`_clearanceId`, clearanceId);
    this.setState({clearanceId});
  }

  set gcId(gcId: string) {
    this.setCookieVal(`_gcId`, gcId);
    this.setState({gcId});
  }

  get gcId(): string {
    if (this.state.gcId) return this.state.gcId;
    return this.getCookieVal(`_gcId`);
  }

  private getCookieVal(suffix: string) {
    return this.cookieService.get(
      `${this.envConfigService.cookieName}${suffix}`
    );
  }

  private setCookieVal(suffix: string, val?: any, expire?: boolean) {
    if (!val) {
      this.cookieService.delete(`${this.envConfigService.cookieName}${suffix}`);
      return;
    }
    this.cookieService.set(
      `${this.envConfigService.cookieName}${suffix}`,
      val,
      expire ? addSeconds(new Date(), 3600) : undefined
    );
  }

  public addTranslatedTelematicKeys(
    language: string,
    telematicKeys: TelematicKeyDto[]
  ) {
    if (!this.translatedTelematicKeys) {
      this.translatedTelematicKeys = new Map<string, TelematicKeyDto[]>();
    }
    this.translatedTelematicKeys.set(language, telematicKeys);
  }

  public getTelematicKeys(language: string) {
    return this.translatedTelematicKeys?.get(language);
  }

  clearCookies() {
    this.cookieService.deleteAll();
  }
}

export interface DataServiceDto {
  access_token: string | null;
  code: string | null;
  brand: string;
  language: string;
  country: string;
  redirectUrl: string | null;
  clearanceId: string | null;
  expiry: Date;
  assignmentUuid: string | null;
  gcId: string | null;
}
