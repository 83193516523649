import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService } from '../../../services/menu/menu.service';
import { MenuItem } from '../../models/menu-item';

@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss']
})
export class MenuDropdownComponent implements AfterViewInit, OnDestroy {
  @Input()
  items: MenuItem[] | undefined;
  @Input()
  parent: MenuItem | undefined;
  @Input()
  submenu = 0;
  @Output()
  navigationEvent = new EventEmitter<any>();
  private subscriptions: Subscription[] = [];

  constructor(private menuService: MenuService) {
    this.subscriptions.push(
      this.menuService.urlChange.subscribe(() => {
        this.setActiveParent();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.setActiveParent();
  }

  showMenu(menu: MenuItem): boolean {
    return menu.show ? menu.show() : true;
  }

  setActiveParent() {
    if (!this.items) {
      return;
    }

    Promise.resolve().then(() => {
      const url = this.menuService.getCurrentUrl();
      this.items?.forEach((item) => {
        const regexp = new RegExp(item.url + '.*', 'g');
        item.active = !!(item.children && url.match(regexp));
      });
    });
  }

  navigation(event: { stopPropagation: () => void }) {
    this.navigationEvent.emit(event);
    event.stopPropagation();
  }
}
