<div class="page-wrapper">
  <ng-container *ngIf="appIsLoading">
    <div class="loader-container">
      <img src="assets/images/loader.gif" alt="loading" class="loader-image"/>
    </div>
  </ng-container>

  <!-- us -->
  <ng-container *ngIf="!appIsLoading && isUSHub()" [class]="'theme-' + theme">
    <app-header
      [brand]="this.brand"
      [title]="title"
      [isUsHub]="isUSHub()"
      (selectedMenuEvent)="selectedMenuEvent($event)"
    ></app-header>
    <div>
      <app-us-vehicle-details
        [errorMsg]="appIsFailed ? getError() : null"
        [vehicleData]="vehicleData"
        [imageUrl]="imageUrl"
        [showDetail]="showPackage"
      ></app-us-vehicle-details>
    </div>
    <div class="container" *ngIf="!showPackage" style="padding-top: 10vh; padding-bottom: 5vh;">
      <p>
        BMW CarData provides you the option to easily manage the access for third
        parties to your telematics data. You can easily click to approve, decline
        or revoke a data access permission previously granted. This means you
        alone decide who receives your data – and who does not. It's your choice.
        So you always have your telematics data under control.
     <br/><br/>
        Refer to the table to see who already has your permission to use selected
        telematics data of your BMW for personalized services and whose access
        request you have so far denied. Furthermore, the table contains current
        requests of third parties for whom your permission is still pending. If
        you want to know more about a BMW CarData access request, just click on
        the corresponding name. Individual transactions can be reviewed by
        requesting a transaction report.
      <br/><br/>
        You can sort the entries of the table by attributes, such as Status of
        Request, in order to have an even better overview. Furthermore, BMW will
        regularly email you information about existing BMW CarData access
        permissions for your vehicle. For further details on the processing of
        your telematics data in the context of BMW CarData, please refer to our
        data privacy policy. Please click on and review a "Data package" before
        choosing "accept"..
      </p>
    </div>

    <div class="container" style="background-color: #F6F6F6" *ngIf="!showPackage">
      <div
        class="row"
        style="
          font-family: 'BMWTypeNext', sans-serif;
          font-size: xx-large;
          padding: 40px 20px;
        "
      >
        <div class="col">Overview of Access Permissions</div>
      </div>
      <app-us-clearance-card
        *ngIf="details.clearance"
        [clearance]="details.clearance"
        [brand]="brand"
        [theme]="theme"
        (showPackageView)="showPackageView($event)"
      ></app-us-clearance-card>
      <app-us-assignment-card
        *ngIf="details.assignment"
        [brand]="brand"
        [theme]="theme"
        [assignment]="details.assignment"
        (showPackageView)="showPackageView($event)"
      ></app-us-assignment-card>
    <div #packageView *ngIf="showPackage">
      <app-package-details-us
        [details]="details"
        [brand]="brand"
        [theme]="theme"
        (showPackageView)="showPackageView($event)"
      ></app-package-details-us>
    </div>
    </div>
  </ng-container>

  <!-- emea -->
  <ng-container *ngIf="!appIsLoading && !isUSHub()" [class]="'theme-' + this.theme">
    <app-header
      [brand]="brand"
      [title]="title"
      (selectedMenuEvent)="selectedMenuEvent($event)"
    ></app-header>
      <div>
        <app-group-vehicle-details
          *ngIf="brand !== 'MINI'"
          [errorMsg]="appIsFailed ? getError() : null"
          [vehicleData]="vehicleData"
          [imageUrl]="imageUrl"
        ></app-group-vehicle-details>
        <app-mini-vehicle-details
          *ngIf="brand === 'MINI'"
          [errorMsg]="appIsFailed ? getError() : null"
          [vehicleData]="vehicleData"
          [imageUrl]="imageUrl"
        ></app-mini-vehicle-details>
      </div>

      <div class="container">
        <div *ngIf="!appIsLoading && showNonPrimaryUserBanner">
          @if(!isUSHub()){
              <div [class]="'notification neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + this.theme">
                <div class="row">
                  <div class="col-1" style="margin-right: -5rem">
                    @if(brand !== 'MINI'){
                      <i class="wi wi-information" aria-hidden="true"></i>
                    } @else {
                      <i class="wi wi-info" aria-hidden="true"></i>
                    }
                  </div>
                  <div class="col-9">
                    <h3>{{ 'non_primary.user.banner.title' | translate }}</h3>
                    <p>{{ 'non_primary.user.banner.message' | translate }}</p>
                  </div>
                  <div class="col-2">
                    <button
                      class="neo-tertiary-btn" (click)="userAction('SECONDARY_USER_UNAUTHORIZED')">
                      Ok
                    </button>
                  </div>
                </div>
              </div>
          }
          @else {
            <div [class]="'notification neo-ds ' + (brand !== 'GROUP' ? brand : 'BMW') + ' theme-' + this.theme">
              <div class="row">
                <div class="col-1" style="margin-right: -5rem">
                  @if(brand !== 'MINI'){
                    <i class="wi wi-information" aria-hidden="true"></i>
                  } @else {
                    <i class="wi wi-info" aria-hidden="true"></i>
                  }
                </div>
                <div class="col-9" style="margin-right: 5rem">
                  <h2 style="margin-left: 8rem;">{{ 'non_primary.user.banner.us.title' | translate }}</h2>
                  <p style="margin-left: 8rem; font-size: 12px;">{{ 'non_primary.user.banner.us.message' | translate }}</p>
                </div>
                <div class="col-2">
                  <button
                    class="neo-tertiary-btn" (click)="userAction('SECONDARY_USER_UNAUTHORIZED')">
                    Ok
                  </button>
                </div>
              </div>
            </div>
          }
        </div>

          <div class="clearances-header content-headline-l">
            {{ 'home.description.title' | translate }}
          </div>
          <div class="clearances-content">
            {{ 'home.description.content' | translate }}
          </div>
        </div>

        <div class="container" *ngIf="clearanceId && details.clearance">
          <app-group-clearance-card
            *ngIf="brand === 'GROUP'"
            [clearance]="details.clearance"
            (showPackageView)="showPackageView($event)"
          ></app-group-clearance-card>
          <app-bmw-clearance-card
            *ngIf="brand === 'BMW'"
            [clearance]="details.clearance"
            (showPackageView)="showPackageView($event)"
          ></app-bmw-clearance-card>
          <app-mini-clearance-card
            *ngIf="brand === 'MINI'"
            [clearance]="details.clearance"
            (showPackageView)="showPackageView($event)"
          ></app-mini-clearance-card>
        </div>

      <div class="container" *ngIf="assignment">
        <app-bmw-assignment-card
          [assignment]="assignment"
          (showPackageView)="showPackageView($event)"
        ></app-bmw-assignment-card>
      </div>
      <div #packageView *ngIf="showPackage">
        <app-package-details-emea
          [details]="details"
          [brand]="brand"
          [theme]="theme"
          (showPackageView)="showPackageView($event)"
        ></app-package-details-emea>
      </div>
  </ng-container>
</div>

<!-- Footer -->
<app-footer></app-footer>

