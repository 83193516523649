import {Injectable} from '@angular/core';

import {env} from '../../environments/environment';
import {Environment} from './environment.interface';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentConfigService {
  private readonly environment: Environment;

  constructor() {
    this.environment = env;
  }

  get config(): Environment {
    return this.environment;
  }

  get isProduction(): boolean {
    return this.environment.production;
  }

  get backendUrl(): string {
    return this.environment.backendUrl;
  }

  get cookieName(): string {
    return this.environment.cookieName;
  }

  get redirectUrl(): string {
    return this.environment.redirectBackUrl;
  }

  get isUSHub(): boolean {
    return this.environment.isUSHub;
  }

  get isE2E(): boolean {
    return this.environment.isE2E;
  }

  get isDev(): boolean {
    return this.environment.isDev;
  }

  get clientId(): string {
    return this.environment.clientId;
  }
}
